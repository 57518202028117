<template>
    <div style="padding: 0 12px;box-sizing: border-box">
        <div class="search-cont" v-if="showMore">
            <img class="search-icon" src="@/assets/slices/search.png" alt="">
            <input class="search-txt" type="text" v-model="searchText" @keydown.enter="searchGame">
        </div>
        <div class="game-body">
            <div class="body-h">
                <div class="noselect">{{ title }}</div>
            </div>
            <div class="hot-list">
                <div
                    class="b-b-card pointer"
                    v-for="items in gameList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img :src="items.logo" alt="">
                    <div class="card-btm">
                        <div class="card-title no-warp">{{items.title}}</div>
                        <div class="card-cont">
                            <img src="@/assets/slices/heart.png" alt="">
                            <div>PLAY</div>
                        </div>
                    </div>
                </div>
                <span v-for="item in 12" :key="item"></span>
            </div>
        </div>
        <div class="game-body" v-if="showMore">
            <div class="body-h">
                <div class="noselect">more fun games</div>
            </div>
            <div class="hot-list">
                <div
                    class="b-b-card pointer"
                    v-for="items in moreList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img :src="items.logo" alt="">
                    <div class="card-btm">
                        <div class="card-title no-warp">{{items.title}}</div>
                        <div class="card-cont">
                            <img src="@/assets/slices/heart.png" alt="">
                            <div>PLAY</div>
                        </div>
                    </div>
                </div>
                <span v-for="item in 12" :key="item"></span>
            </div>
        </div>
        <div class="class-info" v-if="!showMore">
            {{ intro }}
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { scrollToTop } from '@/utils/api'
import { getGameByKey, searchGame, getGame  } from "@/api/games"
import classInfo from "@/utils/classInfo"
export default {
    data() {
        return {
            title: '',
            gameList: [],
            funGame: [],
            moreList: [],
            intro: '',
            searchText: '',
            showMore: false
        }
    },
    mounted() {
        scrollToTop(0);
        eventBus.$on('searchgame',name=>{
            console.log(name)
            let {id,key} = name;
            this.getGameList(id,key)
        })
        let {id,key} = this.$route.query;
        this.getGameList(id,key);
    },
    methods: {
        searchGame(){
            this.getGameList('search',this.searchText);
        },
        goDetails(item){
            this.$router.push({
                path:'/details',
                query:{
                    id:item.id
                }
            })
        },
        async getGameList(id,key){
            let games = [];
            let title = '';
            if(id=='search'){
                games = await searchGame({q:key});
                title = `search found "${key}"`;
                this.moreList = await getGame({count:20});
                this.showMore = true;
            }else if(id=='more'){
                this.intro = classInfo.find(item => item.name === key).intro;
                if(key === 'All'){
                    games = await getGame();
                }else{
                    games = await getGameByKey({category:key});
                }
                title = key + ' games';
                this.showMore = false;
            }else{
                console.log(key);
            }
            this.gameList = games;
            this.title = title;
        }
    },
}
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (min-width: 768px){
    .search-cont{
        width: 327px;
        margin: 0 auto;
    }
}
.search-cont{
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 7px 64px 0px rgba(0,0,0,0.07);
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #ECEBED;
    display: flex;
    align-items: center;
    .search-icon{
        width: 24px;
        height: 24px;
        margin: 0 12px 0;
    }
    .search-txt{
        height: 75%;
        border: none;
        outline: none;
    }
}
.game-body{
    margin: 17px 0 14px;
    .body-h{
        margin-bottom: 5px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 18px;
        color: #273253;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .body-b{
        margin-top: 9px;
        overflow: auto;
        white-space: nowrap;
        @media (min-width: 768px){
            &::-webkit-scrollbar{
                height: 7px;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                background: rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-track{
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                border-radius: 0;
                background: rgba(0,0,0,0.1);
            }
        }
    }
    .hot-list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .b-b-card{
            width: 107px;
            overflow: hidden;
            position: relative;
            background: #FFFFFF;
            border-radius: 8px;
            margin-bottom: 11px;
            img{
                width: 107px;
                height: 107px;
                border-radius: 8px;
            }
            .card-btm{
                padding: 0 8px;
                .card-title{
                    height: 18px;
                    font-family: PT Sans, PT Sans;
                    font-weight: bold;
                    font-size: 14px;
                    color: #273253;
                    line-height: 16px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                }
                .card-cont{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width: 20px;
                        height: 20px;
                    }
                    div{
                        width: 59px;
                        height: 22px;
                        background: #FF8D8D;
                        border-radius: 16px 16px 16px 16px;
                        font-family: PT Sans, PT Sans;
                        font-weight: bold;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 22px;
                        text-align: center;
                        font-style: normal;
                        text-transform: none;
                    }
                }
            }
        }
        span{
            width: 107px;
        }
    }
}
.class-info{
    padding: 8px 12px;
    font-family: Inter, Inter;
    font-weight: 400;
    font-size: 16px;
    color: #505050;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

</style>