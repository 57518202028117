<template>
    <div>
        <div class="detail">
            <div class="details-top" v-if="isPlay">
                <div class="top-img">
                    <img :src="gameInfo.logo" alt="">
                </div>
                <div class="top-title noselect no-warp">
                    {{gameInfo.title}}
                </div>
                <div class="top-btn pointer" @click="()=>{isPlay = false}">
                    PLAY
                </div>                
            </div>
            <div class="gamewin" v-else>
                <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
            </div>
            <div class="details-description noselect">
                <div class="des-top">Description :</div>
                <div class="des-body warp-6">{{gameInfo.synopsis}}</div>
            </div>
            <div class="cont-st">
                <div class="cont-st-h noselect">More Games</div>
                <div class="hot-list">
                    <div
                        class="b-b-card pointer"
                        v-for="items in gameList"
                        :key="items.id"
                        @click="goDetails(items)"
                    >
                        <img :src="items.logo" alt="">
                        <div class="card-btm">
                            <div class="card-title no-warp">{{items.title}}</div>
                            <div class="card-cont">
                                <img src="@/assets/slices/heart.png" alt="">
                                <div>PLAY</div>
                            </div>
                        </div>
                    </div>
                    <span v-for="item in 12" :key="item+'s'"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { getGameById } from "@/api/games"

    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:''
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname

            scrollToTop(0);
        },
        methods: {
            async getGameInfo(id){
                let { game,similarGames } = await getGameById({id})
                this.gameInfo = game;
                this.gameList = similarGames;
            },

            goDetails(item){
                this.gameInfo = item;
                this.isPlay = true;
                scrollToTop(0);
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.warp-6{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.detail{
    padding: 0 14px;
    box-sizing: border-box;
    .details-top{
        margin-bottom: 22px;
        div{
            width: 200px;
            margin: 0 auto;
            text-align: center;
        }
        .top-img{
            width: 200px;
            height: 200px;
            border-radius: 24px;
            margin:24px auto 4px;
            overflow: hidden;
            background: #FFFFFF;
            box-shadow: 0px 7px 64px 0px rgba(0,0,0,0.07);
            img{
                width: 100%;
                height: 100%;
            }
        }
        .top-title{
            margin: 3px auto 13px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 16px;
            color: #273253;
            line-height: 21px;
            text-align: center;
            font-style: normal;
            text-transform: none;
        }
        .top-btn{
            width: 200px;
            height: 40px;
            background: #FF8D8D;
            border-radius: 16px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 40px;
            text-align: center;
            font-style: normal;
            text-transform: none;
        }
    }
    .gamewin{
        width: 100%;
        height: calc(100vh - 50px);
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    .details-description{
        margin-bottom: 19px;
        .des-top{
            margin-bottom: 11px;
            height: 23px;
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 18px;
            color: #273253;
            line-height: 23px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        .des-body{
            font-family: Inter, Inter;
            font-weight: 400;
            font-size: 16px;
            color: #505050;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
    }
    .cont-st {
        .cont-st-h {
            font-family: Inter, Inter;
            font-weight: bold;
            font-size: 18px;
            color: #273253;
            line-height: 23px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-bottom: 12px;
        }
        .hot-list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .b-b-card{
                width: 107px;
                overflow: hidden;
                position: relative;
                background: #FFFFFF;
                border-radius: 8px;
                margin-bottom: 11px;
                img{
                    width: 107px;
                    height: 107px;
                    border-radius: 8px;
                }
                .card-btm{
                    padding: 0 8px;
                    .card-title{
                        height: 18px;
                        font-family: PT Sans, PT Sans;
                        font-weight: bold;
                        font-size: 14px;
                        color: #273253;
                        line-height: 16px;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }
                    .card-cont{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        img{
                            width: 20px;
                            height: 20px;
                        }
                        div{
                            width: 59px;
                            height: 22px;
                            background: #FF8D8D;
                            border-radius: 16px 16px 16px 16px;
                            font-family: PT Sans, PT Sans;
                            font-weight: bold;
                            font-size: 14px;
                            color: #FFFFFF;
                            line-height: 22px;
                            text-align: center;
                            font-style: normal;
                            text-transform: none;
                        }
                    }
                }
            }
            span{
                width: 107px;
            }
        }
    }
}
</style>