<template>
    <div>
        <div class="header">
            <img class="logo pointer" src="@/assets/slices/logo.png" alt="" @click="goIndex">
            <img class="class pointer" src="@/assets/slices/class.png" alt="" @click="showMenu()">
<!--            <img v-else class="class pointer" src="@/assets/slices/class.png" alt="" @click="showMenu(false)">-->
        </div>
        <navMenu v-show="isMenu"></navMenu>
    </div>
</template>

<script>
import navMenu from "@/components/navMenu";
import eventBus from "@/utils/eventBus";
    export default {
        components: {
            navMenu
        },
        data() {
            return {
                searchText: '',
                isMenu: false
            }
        },
        mounted() {
            eventBus.$on('showmenu',i=>{
                this.isMenu = i;
            })
        },
        methods: {
            goIndex(){
                if(this.$route.path == '/'){
                    this.$router.go(0)
                }else{
                    this.$router.push('/')
                }
            },
            showMenu(){
                this.isMenu = !this.isMenu;
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.pointer{
    cursor: pointer;
}
.header{
    height: 50px;
    background: #F6F6FA;
    position: fixed;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    .logo{
        width: 123px;
        height: 30px;
    }
    .class{
        width: 15px;
        height: 15px;
    }
}
</style>