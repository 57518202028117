<template>
    <div style="padding: 0 24px;box-sizing: border-box">
        <adContent></adContent>
        <div class="search-cont">
            <img class="search-icon" src="@/assets/slices/search.png" alt="">
            <input class="search-txt" type="text" v-model="searchText" @keydown.enter="searchGame">
        </div>
        <div class="ad-pic">
            <img src="@/assets/slices/topimg.png" alt="">
        </div>
        <div class="game-body" v-for="(item,key) in gameList" :key="key">
            <div class="body-h noselect">{{key}}</div>
            <div class="body-b">
                <div
                    class="b-b-card pointer"
                    v-for="items in item"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img :src="items.logo" alt="">
                    <div class="card-btm">
                        <div class="card-title no-warp">{{items.title}}</div>
                        <div class="card-cont">
                            <img src="@/assets/slices/heart.png" alt="">
                            <div>PLAY</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="game-blurb noselect">
            Welcome to {{blurbUrl}} Here, various free online games can be played directly in your browser without any downloads. Our collection of online games will provide you with hours ofentertainment. Invite your friends over and enjoy these popular games together. From classic arcade games to car games, cricket games, puzzles, mahjong, and more, there's something for everyone in our game categories. We have carefully selected the best online games, and we review and add new games every day, so you can learn new skills as you play! Let's start playing
        </div>
    </div>
</template>

<script>
import {
    getClassList,
    getGameList
} from "@/api/games";
import adContent from "@/components/adContent/index.vue";
import classIcon from "@/utils/classList";
    export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList: [],
                searchText: '',
                classList: [],
                sClass: '',
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            this.getList();
            this.getClass();
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getList(){
                this.gameList = await getGameList();
            },
            async getClass(){
                let list = await getClassList();
                list.forEach((item,i) => {
                    let data = classIcon.find(items => item.key_name === items.name);
                    if(data){
                        list.splice(i,1,data)
                    }
                })
                this.sClass = list[0].name;
                this.classList = list;
            },
            selectClass(name){
                this.sClass = name;
            },
            searchGame(){
                this.$router.push({
                    path: '/selectGame',
                    query: {
                        id: 'search',
                        key: this.searchText
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (min-width: 768px){
    .search-cont{
        width: 327px;
        margin: 0 auto;
    }
}
.search-cont{
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 7px 64px 0px rgba(0,0,0,0.07);
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #ECEBED;
    display: flex;
    align-items: center;
    .search-icon{
        width: 24px;
        height: 24px;
        margin: 0 12px 0;
    }
    .search-txt{
        height: 75%;
        border: none;
        outline: none;
    }
}
.ad-pic{
    height: 158px;
    margin: 20px 0 32px;
    img{
        display: block;
        height: 158px;
        margin: 0 auto;
    }
}
.game-body{
    margin-bottom: 14px;
    .body-h{
        box-sizing: border-box;
        height: 32px;
        font-family: Inter, Inter;
        font-weight: normal;
        font-size: 20px;
        color: #383838;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .body-b{
        margin-top: 14px;
        overflow: auto;
        white-space: nowrap;
        @media (min-width: 768px){
            &::-webkit-scrollbar{
                height: 7px;
            }
            &::-webkit-scrollbar-thumb{
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                background: rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-track{
                box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                border-radius: 0;
                background: rgba(0,0,0,0.1);
            }
        }
        .b-b-card{
            display: inline-block;
            margin-right: 16px;
            width: 136px;
            overflow: hidden;
            position: relative;
            background: #FFFFFF;
            //box-shadow: 0px 7px 64px 0px rgba(0,0,0,0.07);
            border-radius: 8px;
            img{
                width: 136px;
                height: 136px;
                border-radius: 8px;
            }
            .card-btm{
                padding: 0 8px;
                .card-title{
                    height: 18px;
                    font-family: PT Sans, PT Sans;
                    font-weight: bold;
                    font-size: 14px;
                    color: #273253;
                    line-height: 16px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                }
                .card-cont{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width: 20px;
                        height: 20px;
                    }
                    div{
                        width: 59px;
                        height: 22px;
                        background: #FF8D8D;
                        border-radius: 16px 16px 16px 16px;
                        font-family: PT Sans, PT Sans;
                        font-weight: bold;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 22px;
                        text-align: center;
                        font-style: normal;
                        text-transform: none;
                    }
                }
            }
        }
    }
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #505050;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>