<template>
    <div  v-if="showMenu">
        <div class="nav-menu">
            <div class="menu-item pointer" v-for="item in navList" :key="item.name" @click="clickMenu(item)">
                <div :class="{
                   's-class': item.name === selectMenu
                }">{{item.name}}</div>
            </div>
        </div>
        <div class="mask" @click="closeMask"></div>
    </div>
</template>

<script>
import navList from '@/utils/navMenu'
import { getClassList } from "@/api/games";
import eventBus from '@/utils/eventBus'

    export default {
        data() {
            return {
                navList: [],
                selectMenu: '',
                showMenu: true,
            }
        },
        mounted() {
            this.getclasslist();
        },
        methods: {
            async getclasslist(){
                let navlist = await getClassList();
                navlist.forEach(item => {
                    navList.forEach(e=>{
                        if(item.key_name==e.name){
                            this.navList.push(e)
                        }
                    })
                });
            },
            closeMask(){
                eventBus.$emit('showmenu',false);
            },
            clickMenu(item){
                // this.$store.commit('SET_SELECTMENU',item.name);
                this.selectMenu = item.name;
                eventBus.$emit('showmenu',false);
                if(this.$route.path === '/selectGame'){
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    eventBus.$emit('searchgame',{
                        id:'more',
                        key:item.name
                    });
                }else{
                    this.$router.push({
                        path: '/selectGame',
                        query:{
                            id:'more',
                            key:item.name
                        }
                    })
                }
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
@media (min-width: 768px) {
    .nav-menu,.mask{
        width: 1200px;
    }
}
@media (max-width: 768px) {
    .nav-menu,.mask{
        width: 100%;
    }
}
.nav-menu{
    &::-webkit-scrollbar {
        width: 0;
    }
    position: fixed;
    top: 50px;
    z-index: 999999;
    height: 360px;
    background: #FFFFFF;
    overflow: auto;
    .menu-item{
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-bottom: 1px solid #F6F6FA;
        .s-class{
            color: #FF8D8D;
        }
    }
}
.mask{
    position: fixed;
    bottom: 0;
    height: calc(100vh - 410px);
    background: rgba(0,0,0,0.3);
    z-index: 9999;
}
</style>